import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classes from './orangeHeader.module.css'

const OrangeHeader = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    console.log(navigate)
    const goBack = () => {
        navigate(-1)
    }

    const BackButton = () => {
        const shouldShowBackButton = !location.pathname.includes('order')
        return shouldShowBackButton ? (
            <button onClick={goBack} className={classes.backButton}>
                Назад
            </button>
        ) : null
    }

    return (
        <div>
            <div className={classes.header} style={props.style}>
                <div className={classes.title}>{props.title}</div>
                <BackButton />
            </div>
        </div>
    )
}

export default OrangeHeader
