const JWTManager = () => {
    let refreshEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/refresh`;

    const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint);
    const getRefreshTokenEndpoint = () => refreshEndpoint;

    const getToken = () => localStorage.getItem('accessToken');
    const getRefreshToken = () => localStorage.getItem('refreshToken');

    const setToken = (token) => localStorage.setItem('accessToken', token);
    const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);

    const getRoles = () => localStorage.getItem('userRoles');
    const setRoles = (roles) => localStorage.setItem('userRoles', roles);

    const eraseToken = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userRoles');
        return true;
    };

    return {
        eraseToken,
        getToken,
        getRefreshToken,
        setRefreshTokenEndpoint,
        getRefreshTokenEndpoint,
        setToken,
        setRefreshToken,
        getRoles,
        setRoles
    };
};

export default JWTManager();
