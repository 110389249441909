import React from "react";
import NavPanel from "../NavPanel/NavPanel";

const Reviews = () => {
    return (
        <div>
            <NavPanel/>
            <h1>Отзывы</h1>
        </div>
    )
}

export default Reviews;