import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MasterList from '../../components/ClientPages/Masters/MasterItem';
import OrangeHeader from '../../components/ClientPages/Header/OrangeHeader';

function MasterPage(props) {
    const params = useParams()
    const [employees, setEmployees] = useState();
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        axios.get(`${apiUrl}/api/employees/front/${params.id}`)
            .then(response => {
                if(response.data){
                    setEmployees(response.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setEmployees(false)
                }
                
            })
    }, [])

    const recordData = {
        user_id: Number(params.id),
    }
    if(loading) {
        return (<div>Loading...</div>)
    } else {
        return (
            <div className='client_container'>
                <OrangeHeader title="Выберите мастера" />
                <MasterList employees={employees} recordData={recordData}/>
            </div>
        )
    }
}

export default MasterPage;