import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { formatDateTime } from '../../utils/helper'
import OrangeHeader from './Header/OrangeHeader'
import classes from './success.module.css'
import BrandBtn from './UI/Buttons/BrandBtn'
import Contact from './UI/MainBlock/Contact'
const RecordSuccess = (props) => {
    const [orderData, setOrderData] = useState()
    const [userSettings, setUsersSettings] = useState()
    const [orderDate, setOrderDate] = useState()
    const apiUrl = process.env.REACT_APP_API_URL
    const host = process.env.REACT_APP_HOST
    const [loading, setLoading] = useState(true)
    const params = useParams()
    console.log(orderData)
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Запрос на получение данных заказа
                const orderResponse = await axios.get(
                    `${apiUrl}/api/orders/full/${params.id}`,
                )

                if (orderResponse.data) {
                    setOrderData(orderResponse.data)
                    setOrderDate(formatDateTime(orderResponse.data.date))
                    setUsersSettings(orderResponse.data.projectSetting)
                    console.log(orderResponse)
                    // После получения данных заказа, выполняем второй запрос
                } else {
                    console.log(orderResponse)
                }

                // Завершение загрузки
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData() // Вызов асинхронной функции
    }, [params.id]) // Добавляем params.id в зависимости, чтобы useEffect срабатывал при изменении этого параметра

    console.log(orderDate)

    const TextBlock = (props) => {
        return (
            <div className={classes.text_item}>
                <p className={classes.text_title}>{props.title}</p>
                <p className={classes.text_subtitle}>{props.subtitle}</p>
            </div>
        )
    }
    if (loading) {
        return <div>Loading...</div>
    } else {
        console.log(orderData)
        return (
            <div>
                <div className="client_container">
                    <OrangeHeader
                        title=""
                        style={{ borderRadius: '0 0 76.5px 76.5px' }}
                    />
                    <div className={classes.success_bl}>
                        <h1 className={classes.success_title}>
                            Ваш визит добавлен
                        </h1>
                        <TextBlock
                            title="Услуга"
                            subtitle={orderData.service.name}
                        />
                        <TextBlock
                            title="Мастер"
                            subtitle={orderData.employee.name}
                        />
                        <TextBlock title="Время" subtitle={orderDate} />
                    </div>
                    <div className={classes.contact_centr}>
                        <Contact settings={userSettings} />
                    </div>

                    <Link to={`${host}/client/${orderData.projectId}`}>
                        <BrandBtn body="На главную" />
                    </Link>
                </div>
            </div>
        )
    }
}

export default RecordSuccess
