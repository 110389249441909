import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../components/ClientPages/UI/Header/Header'
import MainBlock from '../../components/ClientPages/UI/MainBlock/MainBlock'
import { Helmet } from 'react-helmet'

const apiUrl = process.env.REACT_APP_API_URL

function ClientPage() {
    const params = useParams()
    const [userData, setUserData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [findUser, setFindUser] = useState(true)
    useEffect(() => {
        axios
            .get(`${apiUrl}/api/project-setting/setting/${params.id}`)
            .then((response) => {
                if (response.data) {
                    setUserData(response.data)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setFindUser(false)
                }
            })
    }, [])

    if (loading) {
        return <div>Loading...</div>
    } else if (!findUser) {
        return <div>Пользователь не найден</div>
    } else {
        return (
            <div>
                <Helmet>
                    <title>{userData?.mainInfo?.name}</title>
                    <meta
                        name="description"
                        content="Создайте сайт с онлайн записью для вашего бизнеса за 20 секунд. Управляйте услугами и клиентами легко и эффективно."
                    />
                </Helmet>
                <Header data={userData} />
                <MainBlock data={userData} />
            </div>
        )
    }
}

export default ClientPage
