import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import classes from './serviceItem.module.css'

const ServiceItem = (props) => {
    const params = useParams()
    const location = useLocation()
    const recordData = location.state.recordData
    location.state.recordData.service_id = props.service.id
    recordData.master_id = Number(params.id)
    return (
        <Link
            to={{ pathname: String(props.service.id) }}
            state={{ recordData: recordData }}
        >
            <div className={classes.service_item}>
                <div className={classes.info_bl}>
                    <div className={classes.title}>{props.service.name}</div>
                    <div className={classes.sub_title}>
                        {props.service.description}
                    </div>
                </div>
                <div className={classes.price_bl}>
                    <div className={classes.price}>{props.service.price} р</div>
                </div>
            </div>
        </Link>
    )
}

const ServicesList = (props) => {
    return (
        <div className={classes.master_item}>
            {props.services.map((service) => {
                return (
                    <ServiceItem
                        service={service}
                        key={service.id}
                        state={{ recordData: props.recordData }}
                    />
                )
            })}
        </div>
    )
}

export default ServicesList
