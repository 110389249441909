import React from 'react';
import classes from './brandBtn.module.css'

const BrandBtn = (props) => {
    console.log(props.grey)
    return (
        <button {...props} style={{width: props.width}}  className={`${classes.btn} ${props.grey ? classes.disabled : ''}`}>{props.body}</button>
    )
}

export default BrandBtn;