import React from 'react'
import classes from './mainBlock.module.css'
import whatsapp from '../assets/whatsapp.png'
import telegram from '../assets/telegram.png'
import instagram from '../assets/instagram.png'
import vkontakte from '../assets/vkontakte.png'

const Contact = (props) => {
    console.log(props)
    console.log(props.social?.whatsapp)
    return (
        <div className={classes.contact}>
            <div className={classes.contact_title}>Контакты</div>
            <div className={classes.contact_social}>
                <a
                    href={`https://wa.me/${props.social?.whatsapp}`}
                    target="__blank"
                >
                    <div className={classes.social_item}>
                        <img src={whatsapp} alt="" />
                        <div className={classes.social_title}>whatsapp</div>
                    </div>
                </a>
                {props.social?.telegram ? (
                    <a
                        href={`https://t.me/${props?.social?.telegram}`}
                        target="__blank"
                    >
                        <div className={classes.social_item}>
                            <img src={telegram} alt="" />
                            <div className={classes.social_title}>telegram</div>
                        </div>
                    </a>
                ) : (
                    ''
                )}
                {props.social?.instagram ? (
                    <a
                        href={`https://t.me/${props?.social?.instagram}`}
                        target="__blank"
                    >
                        <div className={classes.social_item}>
                            <img src={instagram} alt="" />
                            <div className={classes.social_title}>
                                instagram
                            </div>
                        </div>
                    </a>
                ) : (
                    ''
                )}
                {props.social?.vkontakte ? (
                    <a
                        href={`https://vk.com/${props?.social?.vkontakte}`}
                        target="__blank"
                    >
                        <div className={classes.social_item}>
                            <img src={vkontakte} alt="" />
                            <div className={classes.social_title}>vk</div>
                        </div>
                    </a>
                ) : (
                    ''
                )}
            </div>
            <div className={classes.location}>
                <a href={`tel:${props.contact?.phone}`}>
                    <img src="" alt="" />
                    <span>{props.contact?.phone || '79999999999'}</span>
                </a>
                <a href="">
                    <img src="" alt="" />
                    <span>{props.contact?.address || 'Московская 7'}</span>
                </a>
            </div>
        </div>
    )
}

export default Contact
