import React from 'react'
import classes from './mainBlock.module.css'
import whatsapp from '../assets/whatsapp.png'
import telegram from '../assets/telegram.png'
import instagram from '../assets/instagram.png'
import vkontakte from '../assets/vkontakte.png'
import { Link } from 'react-router-dom'
import BrandBtn from '../Buttons/BrandBtn'
import Contact from './Contact'
import { Helmet } from 'react-helmet'

const MainBlock = (props) => {
    const settings = props.data
    const description = props.data?.mainInfo?.description || '123'
    return (
        <div className={classes.main_block}>
            <Helmet>
                <meta name="description" content={description} />
            </Helmet>
            <div className={classes.online}>
                <Link to="master" state={{ recordData: props.data.id }}>
                    <BrandBtn body="Онлайн запись" />
                </Link>
                {/* <div className={classes.subTitle_online}>
                    Нажмите на кнопку для записи онлайн
                </div> */}
            </div>
            <div className={classes.about}>
                <div className={classes.about_title}>
                    {settings?.mainInfo?.title || 'О Салоне'}
                </div>
                <div className={classes.about_body}>
                    {settings?.mainInfo?.description || 'Описание'}
                </div>
                <Contact settings={settings} />
            </div>
        </div>
    )
}

export default MainBlock
