import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Header from './components/Header/Header'
import ModalAuth from './components/Header/ModalAuth/ModalAuth'
import './indexPage.css'
import preview_img from './preview.png'
import hero from './hero.png'
// import iphoneFrame from './img/iphone-frame 1.png'
import oppImg from './img/wallet-filled-money-tool.png'
import iphoneFrame from './img/iphoneFrame.png'
import { Helmet } from 'react-helmet'
const apiUrl = process.env.REACT_APP_API_URL

function IndexPage() {
    const [isModalOpen, setModalOpen] = useState(false)
    const openModal = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    const headerRef = useRef(null)
    const navcontentRef = useRef(null)
    const navactionRef = useRef(null)
    const toToggleRef = useRef([])

    useEffect(() => {
        const handleScroll = () => {
            const scrollpos = window.scrollY

            if (scrollpos > 10) {
                headerRef.current.classList.add('bg-white')
                headerRef.current.classList.add('logo-black')
                navactionRef.current.classList.remove('bg-white')
                navactionRef.current.classList.add('gradient')
                navcontentRef.current.classList.add('navBlack')
                navactionRef.current.classList.remove('text-gray-800')
                navactionRef.current.classList.add('text-white')

                for (let i = 0; i < toToggleRef.current.length; i++) {
                    toToggleRef.current[i].classList.add('text-gray-800')
                    toToggleRef.current[i].classList.remove('text-white')
                }

                headerRef.current.classList.add('shadow')
                navcontentRef.current.classList.remove('bg-gray-100')
                navcontentRef.current.classList.add('bg-white')
            } else {
                headerRef.current.classList.remove('bg-white')
                headerRef.current.classList.remove('logo-black')
                navactionRef.current.classList.remove('gradient')
                navactionRef.current.classList.add('bg-white')
                navactionRef.current.classList.remove('text-white')
                navactionRef.current.classList.add('text-gray-800')
                navcontentRef.current.classList.remove('navBlack')

                for (let i = 0; i < toToggleRef.current.length; i++) {
                    toToggleRef.current[i].classList.add('text-white')
                    toToggleRef.current[i].classList.remove('text-gray-800')
                }

                headerRef.current.classList.remove('shadow')
                navcontentRef.current.classList.remove('bg-white')
                navcontentRef.current.classList.add('bg-gray-100')
            }
        }

        document.addEventListener('scroll', handleScroll)

        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div>
            <Helmet>
                <title>{'Сервис для онлайн записи клиентов! Бесплатно.'}</title>
                <meta
                    name="description"
                    content="Создайте сайт с онлайн записью для вашего бизнеса за 20 секунд. Управляйте услугами и клиентами легко и эффективно."
                />
            </Helmet>
            <Header
                headerRef={headerRef}
                navRef={navcontentRef}
                navactionRef={navactionRef}
            />
            <main>
                <div className="leading-normal tracking-normal text-white gradient">
                    <ModalAuth
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        // type="auth"
                    ></ModalAuth>
                    <div className="pt-24">
                        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                            <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left mainText">
                                <p className="uppercase tracking-loose w-full mainText">
                                    Сотни задач. Один инструмент
                                </p>
                                <h1 className="my-4 mainTitle font-bold leading-tight mainText">
                                    Личный сайт с онлайн записью
                                </h1>
                                <p className="leading-normal mb-8 mainSubTitle mainText">
                                    БЕСПЛАТНО
                                </p>
                                <button
                                    className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                                    onClick={openModal}
                                >
                                    Создать
                                </button>
                            </div>
                            <div className="w-full md:w-3/5 py-6 text-center">
                                <img
                                    className="w-full md:w-4/5 z-50 right-0"
                                    src={hero}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="relative -mt-12 lg:-mt-24">
                        <svg
                            viewBox="0 0 1428 174"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <g
                                    transform="translate(-2.000000, 44.000000)"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                >
                                    <path
                                        d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                        opacity="0.100000001"
                                    ></path>
                                    <path
                                        d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                        opacity="0.100000001"
                                    ></path>
                                    <path
                                        d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                        id="Path-4"
                                        opacity="0.200000003"
                                    ></path>
                                </g>
                                <g
                                    transform="translate(-4.000000, 76.000000)"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                >
                                    <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <section id="targetOpportunities">
                    <h2 class="w-full text-5xl font-bold leading-tight text-center text-gray-800 oppTitle">
                        ВОЗМОЖНОСТИ
                    </h2>
                    <div class="w-full mb-4 oppTitleBorder">
                        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <div className="container flex items-center justify-center oppBody">
                        <div className="w-1/3">
                            {/* Блок с преимуществами слева */}
                            <div className=" p-4 rounded-lg">
                                <div className="imgOpportunitiesDesc mb-5">
                                    <img src={oppImg} alt="" />
                                </div>
                                {/* Здесь размещаете содержимое блока */}
                                <h2 className="oppItemTitle font-bold mb-2">
                                    Онлайн Запись
                                </h2>
                                <p className="oppItemText">
                                    Составьте удобное для вас расписание и
                                    позвольте клиентам записываться 24/7
                                </p>
                            </div>
                            {/* Блок с преимуществами слева */}
                            <div className=" p-4 rounded-lg">
                                <div className="imgOpportunitiesDesc mb-5">
                                    <img src={oppImg} alt="" />
                                </div>
                                {/* Здесь размещаете содержимое блока */}
                                <h2 className="oppItemTitle font-bold mb-2">
                                    База клиентов
                                </h2>
                                <p className="oppItemText">
                                    Все ваши клиенты и история работы с ними в
                                    одном месте. Больше нет нужны все помнить и
                                    записывать.
                                </p>
                            </div>
                            {/* Блок с преимуществами слева */}
                            <div className=" p-4 rounded-lg">
                                <div className="imgOpportunitiesDesc mb-5">
                                    <img src={oppImg} alt="" />
                                </div>
                                {/* Здесь размещаете содержимое блока */}
                                <h2 className="oppItemTitle font-bold mb-2">
                                    Информативность
                                </h2>
                                <p className="oppItemText">
                                    Услуги, цены, отзывы, работы всё в одном
                                    месте и на виду!
                                </p>
                            </div>
                        </div>

                        <div className="relative w-1/3">
                            {/* Изображение с телефоном */}
                            <img
                                src={iphoneFrame}
                                alt="Телефон"
                                className="mx-auto"
                            />
                        </div>

                        <div className="w-1/3">
                            {/* Блок с преимуществами справа */}
                            <div className="p-4 rounded-lg text-right">
                                <div className="imgOpportunitiesDesc mb-5 oppRight">
                                    <img src={oppImg} alt="" />
                                </div>
                                {/* Здесь размещаете содержимое блока */}
                                <h2 className="oppItemTitle font-bold mb-2 oppRight">
                                    Статистика и отчет
                                </h2>
                                <p className="oppItemText oppRight">
                                    Посещаемость сайта, количество записей,
                                    новые клиенты, выручка, расходы и доходы.
                                </p>
                            </div>
                            {/* Блок с преимуществами справа */}
                            <div className="p-4 rounded-lg text-right">
                                <div className="imgOpportunitiesDesc mb-5 oppRight">
                                    <img src={oppImg} alt="" />
                                </div>
                                {/* Здесь размещаете содержимое блока */}
                                <h2 className="oppItemTitle font-bold mb-2 oppRight">
                                    Дизайн
                                </h2>
                                <p className="oppItemText oppRight">
                                    Дизайн вашего сайта словно капля дорого
                                    парфюма привлекает внимание и выделяет ваш
                                    сайт на фоне остальных.
                                </p>
                            </div>
                            {/* Блок с преимуществами справа */}
                            <div className="p-4 rounded-lg text-right">
                                <div className="imgOpportunitiesDesc mb-5 oppRight">
                                    <img src={oppImg} alt="" />
                                </div>
                                {/* Здесь размещаете содержимое блока */}
                                <h2 className="oppItemTitle font-bold mb-2 oppRight">
                                    Выдача в поиске
                                </h2>
                                <p className="oppItemText oppRight">
                                    Автоматическая СЕО оптимизация позволит
                                    выдавать ваш сайт в топ поисковых запросов в
                                    вашем городе
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <button
                            class="oppBtn mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                            onClick={openModal}
                        >
                            Создать сайт
                        </button>
                    </div>
                </section>
                <section id="targetOpportunitiesMobile">
                    <h2 class="w-full font-bold leading-tight text-center text-gray-800 oppTitle">
                        ВОЗМОЖНОСТИ
                    </h2>
                    <div class="w-full mb-4 oppTitleBorder">
                        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <div className="container items-center justify-center oppBody">
                        <div className="relative iphoneMobile">
                            {/* Изображение с телефоном */}
                            <img
                                src={iphoneFrame}
                                alt="Телефон"
                                className="mx-auto iphoneMobileImg"
                            />
                        </div>
                        <div className="flex justify-between oppDuble">
                            <div className="oppLeftBlock">
                                {/* Блок с преимуществами слева */}
                                <div className=" p-4 rounded-lg item">
                                    <div className="imgOpportunitiesDesc mb-5 oppLeft">
                                        <img src={oppImg} alt="" />
                                    </div>
                                    {/* Здесь размещаете содержимое блока */}
                                    <h2 className="oppItemTitle font-bold mb-2 oppLeft">
                                        Онлайн Запись
                                    </h2>
                                    <p className="oppItemText oppLeft">
                                        Составьте удобное для вас расписание и
                                        позвольте клиентам записываться 24/7
                                    </p>
                                </div>
                                {/* Блок с преимуществами слева */}
                                <div className=" p-4 rounded-lg item">
                                    <div className="imgOpportunitiesDesc mb-5 oppLeft oppLeft">
                                        <img src={oppImg} alt="" />
                                    </div>
                                    {/* Здесь размещаете содержимое блока */}
                                    <h2 className="oppItemTitle font-bold mb-2 oppLeft">
                                        База клиентов
                                    </h2>
                                    <p className="oppItemText oppLeft">
                                        Все ваши клиенты и история работы с ними
                                        в одном месте. Больше нет нужны все
                                        помнить и записывать.
                                    </p>
                                </div>
                                {/* Блок с преимуществами слева */}
                                <div className=" p-4 rounded-lg item">
                                    <div className="imgOpportunitiesDesc mb-5 oppLeft oppLeft">
                                        <img src={oppImg} alt="" />
                                    </div>
                                    {/* Здесь размещаете содержимое блока */}
                                    <h2 className="oppItemTitle font-bold mb-2 oppLeft">
                                        Информативность
                                    </h2>
                                    <p className="oppItemText oppLeft">
                                        Услуги, цены, отзывы, работы всё в одном
                                        месте и на виду!
                                    </p>
                                </div>
                            </div>

                            <div className="oppRightBlock">
                                {/* Блок с преимуществами справа */}
                                <div className="p-4 rounded-lg text-right item">
                                    <div className="imgOpportunitiesDesc mb-5 oppRight">
                                        <img src={oppImg} alt="" />
                                    </div>
                                    {/* Здесь размещаете содержимое блока */}
                                    <h2 className="oppItemTitle font-bold mb-2 oppRight">
                                        Статистика и отчет
                                    </h2>
                                    <p className="oppItemText oppRight">
                                        Посещаемость сайта, количество записей,
                                        новые клиенты, выручка, расходы и
                                        доходы.
                                    </p>
                                </div>
                                {/* Блок с преимуществами справа */}
                                <div className="p-4 rounded-lg text-right item">
                                    <div className="imgOpportunitiesDesc mb-5 oppRight">
                                        <img src={oppImg} alt="" />
                                    </div>
                                    {/* Здесь размещаете содержимое блока */}
                                    <h2 className="oppItemTitle font-bold mb-2 oppRight">
                                        Дизайн
                                    </h2>
                                    <p className="oppItemText oppRight">
                                        Дизайн вашего сайта словно капля дорого
                                        парфюма привлекает внимание и выделяет
                                        ваш сайт на фоне остальных.
                                    </p>
                                </div>
                                {/* Блок с преимуществами справа */}
                                <div className="p-4 rounded-lg text-right item">
                                    <div className="imgOpportunitiesDesc mb-5 oppRight">
                                        <img src={oppImg} alt="" />
                                    </div>
                                    {/* Здесь размещаете содержимое блока */}
                                    <h2 className="oppItemTitle font-bold mb-2 oppRight">
                                        Выдача в поиске
                                    </h2>
                                    <p className="oppItemText oppRight">
                                        Автоматическая СЕО оптимизация позволит
                                        выдавать ваш сайт в топ поисковых
                                        запросов в вашем городе
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <button
                            class="oppBtn mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                            onClick={openModal}
                        >
                            Создать сайт
                        </button>
                    </div>
                </section>
                <section id="targetPrice" class="bg-gray-100 py-8">
                    <div class="container mx-auto px-2 pt-4 pb-12 text-gray-800">
                        <h2 class="w-full my-2 font-bold leading-tight text-center text-gray-800 priceTitle">
                            ЦЕНЫ
                        </h2>
                        <div class="w-full mb-4">
                            <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                        </div>
                        <div class="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4 priceBody">
                            {/* <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                                <div class="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                                    <div class="p-8 text-3xl font-bold text-center border-b-4">
                                        Free
                                    </div>
                                    <ul class="w-full text-center text-sm">
                                        <li class="border-b py-4">Thing</li>
                                        <li class="border-b py-4">Thing</li>
                                        <li class="border-b py-4">Thing</li>
                                    </ul>
                                </div>
                                <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                    <div class="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                        £0
                                        <span class="text-base">
                                            for one user
                                        </span>
                                    </div>
                                    <div class="flex items-center justify-center">
                                        <button class="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                            Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                            <div class="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
                                <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                                    <div class="w-full p-8 text-3xl font-bold text-center">
                                        Бесплатно
                                    </div>
                                    <div class="h-1 w-full gradient my-0 py-0 rounded-t"></div>
                                    <ul class="w-full text-center text-base font-bold">
                                        <li class="border-b py-4">
                                            Полный доступ
                                        </li>
                                        {/* <li class="border-b py-4">Thing</li>
                                        <li class="border-b py-4">Thing</li>
                                        <li class="border-b py-4">Thing</li> */}
                                    </ul>
                                </div>
                                <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                    <div class="w-full pt-6 text-4xl font-bold text-center">
                                        до 100
                                        <span class="text-base">
                                            / сотрудников
                                        </span>
                                    </div>
                                    <div class="flex items-center justify-center">
                                        <button
                                            class="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                                            onClick={openModal}
                                        >
                                            Создать
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                                <div class="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                                    <div class="p-8 text-3xl font-bold text-center border-b-4">
                                        Pro
                                    </div>
                                    <ul class="w-full text-center text-sm">
                                        <li class="border-b py-4">Thing</li>
                                        <li class="border-b py-4">Thing</li>
                                        <li class="border-b py-4">Thing</li>
                                    </ul>
                                </div>
                                <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                    <div class="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                        £x.99
                                        <span class="text-base">
                                            / per user
                                        </span>
                                    </div>
                                    <div class="flex items-center justify-center">
                                        <button class="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                            Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </main>
            <div className="gradient">
                <svg
                    class="wave-top"
                    viewBox="0 0 1439 147"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <g
                            transform="translate(-1.000000, -14.000000)"
                            fill-rule="nonzero"
                        >
                            <g class="wave" fill="#f8fafc">
                                <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                            </g>
                            <g
                                transform="translate(1.000000, 15.000000)"
                                fill="#FFFFFF"
                            >
                                <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                                    <path
                                        d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                        opacity="0.100000001"
                                    ></path>
                                    <path
                                        d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                        opacity="0.100000001"
                                    ></path>
                                    <path
                                        d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                        opacity="0.200000003"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <section class="container mx-auto text-center py-6 mb-12">
                    <h2 class="w-full my-2 font-bold leading-tight text-center text-white event-title">
                        Выполните простую регистрацию
                    </h2>
                    <div class="w-full mb-4">
                        <div class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <h3 class="my-4 leading-tight h3Text event-subtitle">
                        получите мощную экосистему уже через 30 секунд
                        бесплатно.
                    </h3>
                    <button
                        class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                        onClick={openModal}
                    >
                        Создать
                    </button>
                </section>
            </div>
            <footer class="bg-white">
                <div class="container mx-auto px-8">
                    <div class="w-full flex flex-col md:flex-row py-6">
                        <div class="flex-1 mb-6 text-black">
                            <a
                                class="text-pink-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                                href="#"
                            >
                                Cclients.ru
                            </a>
                        </div>
                        {/* <div class="flex-1">
                            <p class="uppercase text-gray-500 md:mb-6">Links</p>
                            <ul class="list-reset mb-6">
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        FAQ
                                    </a>
                                </li>
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Help
                                    </a>
                                </li>
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Support
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex-1">
                            <p class="uppercase text-gray-500 md:mb-6">Legal</p>
                            <ul class="list-reset mb-6">
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Terms
                                    </a>
                                </li>
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Privacy
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex-1">
                            <p class="uppercase text-gray-500 md:mb-6">
                                Social
                            </p>
                            <ul class="list-reset mb-6">
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Facebook
                                    </a>
                                </li>
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Linkedin
                                    </a>
                                </li>
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Twitter
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex-1">
                            <p class="uppercase text-gray-500 md:mb-6">
                                Company
                            </p>
                            <ul class="list-reset mb-6">
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Official Blog
                                    </a>
                                </li>
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        About Us
                                    </a>
                                </li>
                                <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a
                                        href="#"
                                        class="no-underline hover:underline text-gray-800 hover:text-pink-500"
                                    >
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <a
                    href="https://www.freepik.com/free-photos-vectors/background"
                    class="text-gray-500"
                ></a>
            </footer>
        </div>
    )
}

export default IndexPage
