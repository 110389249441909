import React from "react";
import classes from './NavPanel.module.css'
import { Link } from "react-router-dom";
const NavPanel = () => {

    return (
        <div>
            <div className={classes.top}>
                <Link to="/">Главная</Link>
                <Link to="/reviews">Отзывы</Link>
            </div>
        </div>
    )
}

export default NavPanel;