import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Reviews from "./components/Reviews/Reviews";
import './components/ClientPages/UI/assets/global.css';
import RecordSuccess from "./components/ClientPages/RecordSuccess";
import ClientPage from "./pages/Client/ClientPage";
import MasterPage from "./pages/Client/MastersPage";
import ServicesPage from "./pages/Client/ServicesPage";
import TimingPage from "./pages/Client/TimingPage";
import IndexPage from "./pages/site/IndexPage";
import 'tailwindcss/tailwind.css'
import { useEffect } from "react";
function App() {

    const NotFoundPage = () => {
        const navigate = useNavigate();

        useEffect(() => {
            // Установка статуса ответа 404
            navigate('/404', { replace: true });
        }, [navigate]);

        return (
            <div style={{ textAlign: 'center', marginTop: '100px' }}>
                <h1>404</h1>
                <p>Страница не найдена</p>
            </div>
        );
    };

    return (
        <div className="App">
            <BrowserRouter>

                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/reviews" element={<Reviews />} />
                    <Route path="/client/:id" element={<ClientPage />} />
                    <Route path="/client/:id/master" element={<MasterPage />} />
                    <Route path="/client/:id/master/:id" element={<ServicesPage />} />
                    <Route path="/client/:id/master/:id/:id" element={<TimingPage />} />
                    <Route path="/order/:id" element={<RecordSuccess />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
