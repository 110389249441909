// CitySearchComponent.jsx

import React, { useState } from 'react'
import cities from '../datas/cities.js'
import './CitySearchComponent.css'

const CitySearchComponent = ({ onCitySelect }) => {
    const [query, setQuery] = useState('')
    const [results, setResults] = useState([])
    const [cityComponent, setCityComponent] = useState('')
    console.log('query: ', query)

    const handleSearch = (event) => {
        const value = event.target.value
        setQuery(value)
        onCitySelect(value)
        setQuery(value)
        setResults(
            cities.filter((city) =>
                city.toLowerCase().includes(value.toLowerCase()),
            ),
        )
    }

    const handleResultClick = (city, event) => {
        event.stopPropagation() // Предотвращаем всплытие события
        setQuery(city) // Устанавливаем выбранный город в качестве значения поля ввода
        setResults([]) // Очищаем результаты поиска
        onCitySelect(city)
    }

    return (
        <div className="city-search-container">
            {' '}
            {/* Применяем класс для контейнера */}
            <input
                type="text"
                name="city"
                value={query}
                onChange={handleSearch}
                placeholder="Введите название города"
                className="city-search-input" // Применяем класс для поля ввода
                autoComplete="off"
            />
            <ul className="city-search-results">
                {' '}
                {/* Применяем класс для списка результатов */}
                {results.map((city, index) => (
                    <li
                        key={index}
                        onClick={(event) => handleResultClick(city, event)} // Добавляем обработчик клика
                    >
                        {city}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default CitySearchComponent
