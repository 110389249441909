import React from 'react'
import classes from './Header.module.css'
import logo from '../assets/1logo.png'
import fon from '../assets/1header.png'

const Header = (props) => {
    return (
        <div className={classes.client_header}>
            <div className={classes.header}>
                <img
                    className={classes.background}
                    src={props.data?.mainInfo?.background?.src || fon}
                    alt=""
                />
                <div className={classes.title}>
                    {props.data?.mainInfo?.name || 'Название'}
                </div>
                <div className={classes.shape}></div>
            </div>

            <div className={classes.nav}>
                {/* <div className={classes.left_nav}>
                    <div className={classes.nav_item}>запись</div>
                    <div className={classes.nav_item} >услуги</div>
                </div> */}
                {/* <div className={classes.right_nav}>
                    <div className={classes.nav_item}>работы</div>
                    <div className={classes.nav_item}>отзывы</div>
                </div> */}
                <div className={classes.logo}>
                    <img
                        className={classes.logoimg}
                        src={props.data?.mainInfo?.logo?.src || logo}
                        alt=""
                    />
                    {/* <img src={logo} alt="" /> */}
                </div>
            </div>
        </div>
    )
}

export default Header
