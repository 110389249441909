// Modal.jsx

import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import './ModalAuth.css'
import authProvider from '../../../../../authProvider'
import CitySearchComponent from '../../search-cities/CitySearchComponent'

const ModalAuth = ({ isOpen, onClose, children, type = 'create' }) => {
    const apiUrl = process.env.REACT_APP_API_URL
    const [modalType, setType] = useState(type)
    const [formData, setFormData] = useState({
        login: '',
        password: '',
        passwordRepeat: '',
    })
    const [isBtnSuccess, setIsBtnSuccess] = useState(false)
    const [selectedCity, setSelectedCity] = useState('')
    const buttonAuth = useRef(null)
    const buttonCreate = useRef(null)
    const [message, sendMessage] = useState(false)
    const handleCitySelect = (city) => {
        setSelectedCity(city)
    }

    useEffect(() => {
        //Проверяем все инпуты из formData и заполнен ли город
        const allFieldsFilled = Object.values(formData).every(
            (field) => field.trim() !== '',
        )
        if (modalType === 'auth' && buttonAuth.current) {
            if (formData.login && formData.password) {
                buttonAuth.current.classList.add('btnCreate')
                buttonAuth.current.classList.remove('btnGrey')
                buttonAuth.current.removeAttribute('disabled')
            } else {
                buttonAuth.current.classList.add('btnGrey')
                buttonAuth.current.classList.remove('btnCreate')
                buttonAuth.current.setAttribute('disabled', 'disabled')
            }
        }

        if (modalType === 'create' && buttonCreate.current) {
            if (
                formData.login &&
                formData.password &&
                formData.passwordRepeat &&
                formData.password === formData.passwordRepeat &&
                selectedCity.length >= 2
            ) {
                buttonCreate.current.classList.add('btnCreate')
                buttonCreate.current.classList.remove('btnGrey')
                buttonCreate.current.removeAttribute('disabled')
            } else {
                buttonCreate.current.classList.add('btnGrey')
                buttonCreate.current.classList.remove('btnCreate')
                buttonCreate.current.setAttribute('disabled', 'disabled')
            }
        }

        let timeoutId
        // Устанавливаем обработчик клика с задержкой
        const handleClickOutside = (event) => {
            if (!event.target.closest('.modalBl')) {
                onClose()
            }
        }

        if (isOpen) {
            timeoutId = setTimeout(() => {
                document.addEventListener('click', handleClickOutside)
            }, 100)
        }

        // Очищаем timeout и удаляем обработчик клика при размонтировании компонента
        return () => {
            clearTimeout(timeoutId)
            document.removeEventListener('click', handleClickOutside)
        }
    }, [isOpen, onClose, formData, selectedCity, modalType])

    if (!isOpen) {
        return null
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => {
            const newState = {
                ...prevState,
                [name]: value,
            }

            // Выполняем проверки на обновленном состоянии
            if (
                newState.passwordRepeat &&
                newState.password &&
                newState.passwordRepeat.length >= 3 &&
                newState.passwordRepeat !== newState.password
            ) {
                sendMessage('Пароли не совпадают')
            } else {
                sendMessage('')
            }

            return newState // Возвращаем новое состояние
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (modalType === 'auth') {
            const params = {
                username: formData.login,
                password: formData.password,
            }
            authProvider.login(params).then((response) => {
                console.log(response)
                if (response && response.status === 201) {
                    window.location.href = 'https://cclients.ru/admin'
                } else {
                    sendMessage(`Логин или пароль введены не верно!`)
                }
            })
        }
        if (modalType === 'create') {
            const params = {
                username: formData.login,
                password: formData.password,
                passwordRepeat: formData.passwordRepeat,
                city: formData.city,
            }
            if (formData.password === formData.passwordRepeat) {
                authProvider.registration(params).then((response) => {
                    console.log(response.status)
                    if (response && response.status === 201) {
                        window.location.href = 'https://cclients.ru/admin'
                    } else {
                        sendMessage(`Логин или пароль введены не верно!`)
                    }
                })
            }
        }
    }

    return (
        <div className="modal-overlay">
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="modalBl bg-white rounded-lg overflow-hidden shadow-xl p-8 w-full max-w-md">
                    <div className="text-center mb-8">
                        <h2 className="text-2xl font-bold text-gray-800 text-left">
                            {modalType === 'auth'
                                ? 'Авторизация'
                                : 'Регистрация'}
                        </h2>
                    </div>
                    <div className="flex justify-between mb-10">
                        <button
                            className={`flex-1 text-center py-2 border border-gray-300 rounded-md mr-2  ${modalType === 'auth' ? 'btnTopActive' : 'btnTop'}`}
                            onClick={() => setType('auth')}
                        >
                            Войти
                        </button>
                        <button
                            className={`flex-1 text-center py-2 border border-gray-300 rounded-md ml-2  ${modalType === 'create' ? 'btnTopActive' : 'btnTop'}`}
                            onClick={() => setType('create')}
                        >
                            Создать аккаунт
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} className="mb-4">
                        <div className="mb-4">
                            <input
                                type="text"
                                placeholder="Телефон"
                                className="w-full py-2 px-3 border border-gray-300 rounded-md text-back text-black"
                                name="login"
                                value={formData.login}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="password"
                                name="password"
                                placeholder="Пароль"
                                className="w-full py-2 px-3 border border-gray-300 rounded-md text-back text-black"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                        {modalType === 'create' ? (
                            <div className="mb-4">
                                <input
                                    type="password"
                                    placeholder="Повторите пароль"
                                    className="w-full py-2 px-3 border border-gray-300 rounded-md text-back text-black"
                                    name="passwordRepeat"
                                    value={formData.passwordRepeat}
                                    onChange={handleChange}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        {modalType === 'create' ? (
                            <CitySearchComponent
                                onCitySelect={handleCitySelect}
                            />
                        ) : (
                            ''
                        )}
                        <div className="sendMessage">{message}</div>
                        {modalType === 'auth' ? (
                            <button
                                type="submit"
                                className="w-full text-white py-2 rounded-md btnGrey"
                                ref={buttonAuth}
                            >
                                Войти
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="w-full text-white py-2 rounded-md btnGrey"
                                ref={buttonCreate}
                            >
                                Создать
                            </button>
                        )}
                    </form>
                    <button onClick={onClose} className="w-full text-gray-600">
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalAuth
