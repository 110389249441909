import React from 'react'
import { Link } from 'react-router-dom'
import classes from './masterItem.module.css'

const MasterItem = (props) => {
    const recordData = props.state.recordData
    recordData.master_id = props.master.id
    return (
        <Link
            to={{ pathname: String(props.master.id) }}
            state={{ recordData: recordData }}
        >
            <div className={classes.master_item}>
                <img
                    src={
                        props?.master?.data?.avatar?.src ??
                        '/img/client_master/1.png'
                    }
                    alt=""
                />
                <div className={classes.info_bl}>
                    <div className={classes.name}>{props.master.name}</div>
                    <div className={classes.profession}>
                        {props.master.profession}
                    </div>
                    <div className={classes.other}>{props.master.other}</div>
                </div>
                <div className={classes.ui_element}></div>
            </div>
        </Link>
    )
}

const MasterList = (props) => {
    return (
        <div className={classes.masters}>
            {props.employees.map((employee) => {
                console.log(employee)
                return (
                    <MasterItem
                        master={employee}
                        state={{ recordData: props.recordData }}
                        key={employee.id}
                    />
                )
            })}
        </div>
    )
}

export default MasterList
